<template>
  <div @click="toGoodsInfo(product.goods_id)" class="serviceCard">
    <van-panel>
      <template v-slot:header>
        <div class="header">
          <div class="header-info">
            <p class="header-date">{{product.create_time}}</p>
          </div>
          <p class="header-state">{{statusText}}</p>
        </div>
      </template>
      <div class="content">
        <van-card  class="border_">
          <template v-slot:thumb>
            <van-image fit="contain" :src="product.goods_pic" />
          </template>
          <template v-slot:title>
            <div class="content-wrap">
              <div class="content-left">
                <p class="content-title">{{product.goods_title}}</p>
              </div>
              <div class="content-right">
                <div class="content-right-wrap">
                  <p class="content-nowrap">x {{product.sale_price}}</p>
                </div>
                <p class="goodsNum">x {{product.number}}</p>
              </div>
              <p class="goodsInfo">{{product.goods_title == product.product_name ? '' : product.product_name}}</p>
            </div>
          </template>
        </van-card>
      </div>
      <template v-slot:footer>
        <div class="footer">
          <!-- 商品价格信息和操作 -->
          <div class="footer-pay flexEnd">
            <div class="pay-price">
              <!-- 实付： -->
              <div class="pay-balance">{{language.l_60}}<span>$</span><span class="simSize">{{data.amount}}</span></div>
              <div class="pay-state">
                <!-- 查看详情 -->
                <van-button @click.stop="toServiceInfo(product.after_sale_id)" plain round type="default" class="pay-btn">{{language.l_312}}</van-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],  //售后列表
      paramsLsit: {
        order_sn: '',
        username: '',
        contact_phone: '',
        type: '',
        reason: '',
        status: '',
        create_time: '',
      },
      language: this.$store.getters.language
    }
  },
  props: {
    data: Object
  },
  methods: {
    toGoodsInfo(id) { // 跳转商品详情
      this.$router.push({name: 'GoodsInfo', params: {id}})
    },
    toServiceInfo(id) { // 跳转售后详情
      this.$router.push({name: 'ServiceInfo', params:{ id}})
    },
    afterSaleList() {
      afterSale().then(res => this.list = res.data.data)
    },
  },
  computed: {
    statusText() {
      const {status} = this.data;
      if(status == 0) return this.language.l_61  //待处理
      if(status == 1) return this.language.l_62  //处理中
      if(status == 2) return this.language.l_52  //售后完成
      if(status == 3) return this.language.l_63  //售后拒绝
      if(status == 4) return this.language.l_64  //已撤销
      if(status == 5) return this.language.l_43  //已签收
    },
    product() {return this.data.goods[0]}
  }
}
</script>

<style lang="less" scoped>
.serviceCard {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  overflow: hidden;
  .van-hairline--top::after {
    border-top-width: 0;
  }
  .van-panel__footer {
    padding: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    .header-date {
      font-size: 12px;
      color: #999999;
    }
    .header-state {
      text-align: right;
      width: 100px;
      color: #f44;
    }
  }

  .content {
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 10px;
    .van-card {
      border-radius: 10px;
      box-sizing: border-box;
      padding: 10px;
      .van-card__thumb {
        box-shadow: 1px 0px 2px rgba(0,0,0,.1);
        background-color: #fff;
        border-radius: 8px;
        .van-image {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .content-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 1.4;
        .content-left {
          .content-desc {
            color: #999999;
            margin-top: 5px;
          }
        }
        .content-right {
          display: flex;
          justify-content: space-between;
          p {
            margin-top: 5px;
          }
          .content-nowrap {
            white-space: nowrap;
          }
          .bigSize {
            font-size: 12px;
          }
          .oldPrice {
            text-decoration: line-through
          }
          .goodsNum {
            color: #999999;
          }
        }
        .goodsInfo {
          text-align: right;
          margin-top: 5px;
          color: #999999;
          box-sizing: border-box;
          padding-left: 50%;
        }
      }
    }
  }

  .footer {
    position: static;
    box-sizing: border-box;
    padding: 0 10px 10px;

    .footer-pay {
      display: flex;
      justify-content: space-between;
      &.flexEnd {
        justify-content: flex-end;
      }
      .pay-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 6px;
        .van-image {
          width: 50px;
          height: 50px;
          margin-top: 5px;
        }
      }
      .pay-price {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: right;
        font-size: 12px;
        margin-top: 6px;
        .pay-balance {
          margin-top: 5px;
          span {
            color: red;
          }
        }
        .pay-state {
          
          margin-top: 6px;
          .pay-btn {
            font-size: 12px;
            padding: 0 8px;
            height: 24px;
            line-height: 22px;
            margin-left: 10px;
            border-radius: 4px;
            border-color: red;
            color: red;
            &.van-button--danger {
                background-image: @grad_left;
                border-color: transparent;
            }
          }
        }
        .bigSize {
          font-size: 16px;
        }
        .simSize {
          font-size: 12px;
        }
      }
      
    }
  }
}
</style>