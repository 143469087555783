<template>
  <div id="service">
    <div class="tabs">
      <van-tabs @change="selectTab" v-model="active" color="red" line-width="60" sticky border>
        <!-- 全部 -->
        <van-tab :title="language.l_266"></van-tab>
        <!-- 待处理 -->
        <van-tab :title="language.l_61"></van-tab>
      </van-tabs>
    </div>

    <div v-if="list.length > 0" class="container">
      <div class="list">
        <van-list @load="onReachBottom" v-model="loading" :finished="finished">
          <ServiceCard v-for="(item, index) in list" :key="index" :data="item"/>
        </van-list>
      </div>
    </div>
    <div v-else class="no-data"></div>
  </div>
</template>

<script>
import ServiceCard from '@cpt/ServiceCard';
import {afterSale} from '@api'

export default {
  data() {
    return {
      params: {
        page: 1,
        page_size: 10,
        status: ''
      },
      active: 0,
      list: [],
      count: 0,
      loading: false,
      finished: true,
      language: this.$store.getters.language
    }
  },
  components: {
    ServiceCard
  },
  created() {
    this.selectTab(this.active)
  },
  methods: {
    selectTab(active) { // 选择tab
      this.finished = true;
      const {params} = this
      params.page = 1
      if(active == 0){this.getData(params)}
      if(active == 1){
        params.status = 0
        this.getData(params)
      }
    },
    onReachBottom() { // 上拉加载
      const { params, count, list } = this;
      const maxPage = Math.ceil(count / params.page_size); // 最大页码
      this.getData(params, res => {
        this.loading = false;
        this.list = [...list, ...res.data];
        this.params.page++;
        if(this.params.page > maxPage) {
          this.params.page--;
          this.finished = true;
        }
      })
    },
    getData(params, callback) {
      afterSale(params).then(res => {
        if(callback) return callback(res.data);
        const {count, data} = res.data;
        this.finished = true;
        this.list = data;
        this.count = count;
        if(count > params.page) {
          this.finished = false;
          this.params.page++;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
#service {
  .safe_padding_bottom();
  .tabs {
    .van-tabs {
      /deep/ .van-tab--active {
        span {
          font-weight: bold;
        }
      }
    }
  }
  .container {
    margin: 10px;
    .list {
      .van-list {
        /deep/ .serviceCard {
          // background: yellow;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .no-data {
    width: 300px;
    height: 200px;
    margin: 150px auto;
    background-image: url('~@img/order/no-order.png');
    background-size: 100%;
    background-position: center;
  }
}
</style>